const config = {
  aws_project_region: process.env.REACT_APP_AWS_REGION,
  aws_cognito_region: process.env.REACT_APP_AWS_REGION,
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id:
    process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
  authenticationFlowType: 'USER_PASSWORD_AUTH',
  mandatorySignIn: true,
  oauth: {
    domain: `auth.${process.env.REACT_APP_DOMAIN}`,
    scope: [
      'phone',
      'email',
      'openid',
      'profile',
      'aws.cognito.signin.user.admin',
    ],
    redirectSignIn: process.env.REACT_APP_CI
      ? `${process.env.REACT_APP_BASE_URL}/`
      : `${process.env.REACT_APP_HOST}/`,
    redirectSignOut: process.env.REACT_APP_CI
      ? `${process.env.REACT_APP_BASE_URL}/`
      : `${process.env.REACT_APP_HOST}/`,
    responseType: 'code',
  },
}

export default config
