import {baseURL, getHeaders, withMassageInsights} from './utils'
import {toFilteredQueryString} from '../utils'

const endpoint = `${baseURL}/api/insights/v2`

export const fetchInsightsV2 = withMassageInsights(async options => {
  const {search: _search, signal} = options
  const search = toFilteredQueryString(_search, ['actionable_opportunities'])
  const response = await fetch(endpoint.concat(search), {
    headers: await getHeaders(),
    signal,
  })
  const result = await response.json()
  return result
})
