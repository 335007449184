export const SET_INSIGHTS_V2_LOADING = 'SET_INSIGHTS_V2_LOADING'
export const SET_INSIGHTS_V2_NOT_LOADING = 'SET_INSIGHTS_V2_NOT_LOADING'
export const SET_INSIGHTS_V2_FILTERS = 'SET_INSIGHTS_V2_FILTERS'
export const SET_INSIGHTS_V2_DATA = 'SET_INSIGHTS_V2_DATA'
export const SET_INSIGHTS_V2_LOADING_DATA = 'SET_INSIGHTS_V2_LOADING_DATA'
export const SET_INSIGHTS_V2_NOT_LOADING_DATA =
  'SET_INSIGHTS_V2_NOT_LOADING_DATA'
export const SET_INSIGHTS_V2_ERROR = 'SET_INSIGHTS_V2_ERROR'
export const SET_INSIGHTS_V2_PROCEDURE_GROUPS =
  'SET_INSIGHTS_V2_PROCEDURE_GROUPS'
export const SET_INSIGHTS_V2_SURGEONS = 'SET_INSIGHTS_V2_SURGEONS'
export const SET_INSIGHTS_V2_OPPORTUNITY_TYPES =
  'SET_INSIGHTS_V2_OPPORTUNITY_TYPES'
export const SET_INSIGHTS_V2_COMPARE_BY = 'SET_INSIGHTS_V2_COMPARE_BY'
export const SET_INSIGHTS_V2_IS_ACTIONABLE = 'SET_INSIGHTS_V2_IS_ACTIONABLE'
export const SET_INSIGHTS_V2_ACTIONABLE_OPPORTUNITY_TYPES =
  'SET_INSIGHTS_V2_ACTIONABLE_OPPORTUNITY_TYPES'
export const SET_INSIGHTS_V2_DEPARTMENTS = 'SET_INSIGHTS_V2_DEPARTMENTS'
export const SET_INSIGHTS_V2_UNSELECT_DEPARTMENT =
  'SET_INSIGHTS_V2_UNSELECT_DEPARTMENT'
export const SET_INSIGHTS_V2_UNSELECT_SUB_DEPARTMENT =
  'SET_INSIGHTS_V2_UNSELECT_SUB_DEPARTMENT'
